<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchConditionsDatePicker"
                    format="YYYY-MM-DD"
                    type="lookup-condition"
                    v-model="bsnDateValueModel"
                    :notClear="true"
                    :isGetDateInfo="true"
                    :menuId="396"
                    :isNotFutureDaySelect="true"
                    @change="onBsnDateChange"
                />
              </li>
              <li class="item text">
                <!-- TODO : getDayOfWeekCaptionColor의 첫번째 arg인 date값이 null이면 bsnCode와 dwCode로 색상 가져오도록 해놓았음 -->
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      null,
                      bsnDateInfo.bsnCode,
                      bsnDateInfo.dwCode
                    ),
                  }"
              >{{ dayOfWeekCaption }}</span
              >) (<span
                  :style="{
                    color: commonCodesGetColorValue(
                      'BSN_CODE',
                      bsnDateInfo.bsnCode
                    ),
                  }"
              >{{
                  commonCodesGetComName('BSN_CODE', bsnDateInfo.bsnCode)
                }}</span
              >)
              </li>
            </ul>
          </li>
          <li class="field subdivision">
            <div class="title">부구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    ref="searchConditionsPartDiv"
                    v-bind="partDivDropdownListProps"
                    v-model="partDiv"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="notArrangementTimeFlag"
                          value="false"
                      />
                      <i></i>
                      <div class="label">미배치 타임</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="rainCancelTimeFlag"
                          value="true"
                      />
                      <i></i>
                      <div class="label">우천취소 타임</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="isTimeListLineOption"
                        value="true"
                      />
                      <i></i>
                      <div class="label">라인옵션</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="handleFetchClick"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field builtinNameCaddySearch">
            <div class="title">내장객명/캐디 찾기</div>
            <ul class="content">
              <li class="item input">
                <input-text
                    ref="builtinNameCaddy"
                    v-model="query"
                    @focus="handleQueryFocus"
                    @keydown.native.enter="handleQueryKeydownEnter"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        buttonDiv="GET"
                        @click.native="handleSearchClick"
                    >
                      찾기
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field automaticInquiry">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="autoInquiryFlag"
                          v-model="autoInquiryFlag"
                      />
                      <i></i>
                      <div class="label" for="autoInquiryFlag">자동조회</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="content">
              <li class="item input">
                <input
                    autocapitalize="off"
                    class="e-input"
                    type="number"
                    id="autoInquirySecond"
                    min="10"
                    v-model="autoInquirySecond"
                    :disabled="autoInquiryFlag"
                />
              </li>
              <li class="item text">초</li>
              <li class="item text" v-if="autoInquiryFlag">
                (다음갱신: {{ autoInquiryRemainingSeconds }})
              </li>
            </ul>
          </li>
        </ul>
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">코스구분</div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li v-for="(courseDivOption, idx) in courseDivOptions" :key="idx" style="display: inline">
                    <label>
                      <input
                          :key="idx"
                          :value="courseDivOption.comCode"
                          type="checkbox"
                          name="courseDivOptionsGrp"
                          id="courseDivOptionsGrp"
                          v-model="courseDiv"
                          @change="onCourseDivChanged"
                      />
                      <i></i>
                      <div class="label" for="courseDivOptionsGrp">{{courseDivOption.comName}}</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
                ref="shortcutMenuDropdownButton"
                cssClass="lookup-button-dropdown"
                :items="shortcutMenuItems"
                @select="onShortcutMenuSelected"
            >
              바로가기
            </ejs-dropdownbutton>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article
          :class="[
          'body-article',
          visibleArticleRight ? $t('className.article.rightOpened') : '',
          {
            'dev-body-article-active-team__information': isTeamInformationVisible,
          },
          isCaddieArrangementViewFixed ? $t('className.article.fixed') : '',
        ]"
      >
        <div
            :class="[
                'article-left',
                !isDetailViewOpened ? 'active' : undefined,
            ]"
            :style="{width: !isDetailViewOpened ? '100%' : `calc(100% - ${detailViewSizeWidthOffset})`}"
        >
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01 padding-0">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">타임 목록</div>
                <div class="header-caption">
                  [{{
                    numberWithCommas(reservationsCountsEachStatus['ALL_TEAM'])
                  }}건]
                </div>
                <ul class="header-label">
                  <li class="field">
                    <div class="title">타임상태</div>
                    <ul class="content">
                      <li class="item">
                        <i :style="resveTeamItalicStyle"></i>
                        <div class="label">
                          예약:
                          {{ reservationsCountsEachStatus['RESVE_TEAM'] }}팀
                        </div>
                      </li>
                      <li class="item">
                        <i :style="checkinTeamItalicStyle"></i>
                        <div class="label">
                          체크인:
                          {{ reservationsCountsEachStatus['CHKIN_TEAM'] }}팀
                        </div>
                      </li>
                      <li class="item">
                        <i :style="argmtTeamItalicStyle"></i>
                        <div class="label">
                          배치:
                          {{ reservationsCountsEachStatus['ARGMT_TEAM'] }}팀
                        </div>
                      </li>
                      <li class="item">
                        <i :style="unargmtTeamItalicStyle"></i>
                        <div class="label">
                          미배치:
                          {{ reservationsCountsEachStatus['UNARGMT_TEAM'] }}팀
                        </div>
                      </li>
                      <li class="item">
                        <i :style="rainTeamItalicStyle"></i>
                        <div class="label">
                          우천:
                          {{ reservationsCountsEachStatus['RAIN_TEAM'] }}팀
                        </div>
                      </li>
                      <li class="item">
                        <i :style="addVisitTeamItalicStyle"></i>
                        <div class="label">
                          추가내장:
                          {{ reservationsCountsEachStatus['ADD_VISIT_TEAM'] }}팀
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">캐디명</div>
                    <ul class="content">
                      <li class="item">
                        <i :style="selfOpenFlagItalicStyle"/>
                        <div class="label">
                          셀프캐디
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="header-right">
                <div class="header-switch">
                  <div class="title">고정</div>
                  <div class="switch">
                    <ejs-switch
                        v-model="isCaddieArrangementViewFixed"
                        :checked="isCaddieArrangementViewFixed"
                    ></ejs-switch>
                  </div>
                </div>
              </div>
            </div>
            <div
                :class="[
                'section-body',
                {
                  'dev-reservation-course-num-2':
                    reservationsAndCaddies.courses.length === 2,
                  'dev-reservation-course-num-3':
                    reservationsAndCaddies.courses.length === 3,
                  'dev-reservation-course-num-4':
                    reservationsAndCaddies.courses.length === 4,
                },
              ]"
            >
              <template
                  v-for="(
                  { courseCode, reservations: dataSource }, courseReserveTimeIdx
                ) in reservationsAndCaddies.courses"
              >
                <div
                    v-bind:key="`div-courseReserveTime-${courseCode}`"
                    :class="[
                    'body-box',
                    courseReserveTimeIdx === courseReserveTimeActiveIndex &&
                      $t('className.reservation.reservationActive'),
                    courseReserveTimeIdx === courseReserveTimeActiveIndex &&
                    'select-flex'
                  ]"
                    v-show="courseCodeFilter.includes(courseCode)"
                    @click="
                    handleReserveTimeDivClick($event, { courseReserveTimeIdx })
                  "
                    :style="
                    isCaddieArrangementViewFixed==true
                    ?widthBox
                    :`flex:${1/courseCodeFilter.length}`"
                    :id="`courseGrid${courseReserveTimeIdx}`"
                >
                  <div class="body-header">
                    {{ getCourseNameFromCommonCode(courseCode) }}
                  </div>
                  <ejs-grid-wrapper
                      ref="coursesGrids"
                      v-bind="courseReserveTimeGridProps"
                      :dataSource="dataSource"
                      @dataBound="handleDataBound"
                      @keyPressed="handleGridKeyPressed"
                      @headerCellInfo="courseReserveTimeGridHeaderCellInfo"
                      @queryCellInfo="courseReserveTimeGridQueryCellInfo"
                      @recordClick="handleCourseReserveTimeGridRecordClick"
                      :isShowProgress="false"
                      :isAutoSelectRow="false"
                      :isAutoSelectCell="false"
                  />
                </div>
              </template>
            </div>
          </section>
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section
              :class="[
              'article-section',
              'section-02',
              'accordion',
              { close: !isTeamInformationVisible },
            ]"
          >
            <div class="section-header">
              <div class="header-left">
                <div
                    class="header-title"
                    @click="handleTeamInformationHeaderClick"
                >
                  팀 정보
                </div>
              </div>
              <div class="header-caption">
                [{{
                  getCourseNameFromCommonCode(teamInformation.courseCode)
                }}코스 <strong>{{ teamInformation.startTime }}</strong
              ><span v-if="teamInformation.grpName !== undefined"
              >, 단체명 - {{ teamInformation.grpName }}</span
              >]
              </div>
            </div>
            <div class="section-body">
              <div class="body-card" v-if="isTeamSelected">
                <ul class="box" :style="{ width: memberLinkCardWidth + 'px' }">
                  <!-- 활성 : active -->
                  <li
                      class="card"
                      v-for="visitor in teamInformation.visitors"
                      :key="`visitor-${visitor.visitId}`"
                      :style="visitor.argmtAditFlag === '1' && addVisitTeamItalicStyle"
                  >
                    <div class="photo">
                      <div
                          v-if="visitor['imagePath']"
                          class="item"
                          :style="{
                          backgroundImage: `url(${visitor['imagePath']})`,
                        }"
                      ></div>
                    </div>
                    <ul class="content">
                      <li class="item">
                        <strong>{{ visitor.visitName }}</strong>
                        ({{
                          getSexLocaleStringFromCommonCode(visitor.sexCode)
                        }}
                        {{ visitor.lockerPrtNo }})
                      </li>
                      <li class="item">
                        {{ memberNoFormatter(visitor.memberNo) + " " + getMemberDivLocaleStringFromCommonCode(visitor.memberDiv,) }}
                      </li>
                      <li class="item">
                        확정 :
                        <span v-if="visitor.visitConfirmFlag">
                          <strong>√</strong>
                        </span>&nbsp;
                        <span
                          class="item hole"
                          :class="{
                            'dev-hole__changed':
                            visitor['changeHoleDiv'] &&
                            visitor['changeHoleDiv'] !== visitor.holeDiv,
                          }"
                          @click="handleHoleDivClick(visitor)"
                        >
                          <span>{{
                              getHoleDivLocaleStringFromCommonCode(visitor.holeDiv)
                            }}</span>
                          <span
                            v-if="
                              visitor['changeHoleDiv'] &&
                              visitor['changeHoleDiv'] !== visitor.holeDiv
                            "
                          >
                            →
                            {{
                              getHoleDivLocaleStringFromCommonCode(
                                visitor['changeHoleDiv'],
                              )
                            }}
                          </span>
                        </span>
                      </li>
                      <li class="item" v-if="visitor.insertDt">
                        체크인 : <strong>{{visitor.insertDt}}</strong>
                      </li>
                      <li class="item" v-if="visitor.contactTel">
                        연락처 : <strong>{{cellphoneNumberFormatter(visitor.contactTel)}}</strong>
                      </li>
                      <li class="item" v-if="visitor.bagName">
                        백네임 : {{visitor.bagName}}
                      </li>
                      <li class="item">
                        VOC :
                        <span
                            :style="visitor.vocCount && 'cursor: pointer'"
                            @click="visitor.vocCount && fastVocMove(visitor)"
                        >
                          <strong>{{visitor.vocCount?visitor.vocCount+'건':'-'}}</strong>
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <div
            class="article-right"
            v-if="isDetailViewOpened"
            :style="{width: `${detailViewSizeWidthOffset}`}"
        >
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-03">
            <div class="section-caption" style="display: flex">
              <ul class="caption-label caption-button no-padding-horizontal" style="width: 100%; justify-content: center">
                <li>
                  <div class="item">F1</div>메뉴
                </li>
                <li>
                  <div class="item">F3</div>추가내장
                </li>
                <li>
                  <div class="item">F9</div>메모
                </li>
                <li class="close">
                  <erp-button
                      buttonDiv="CLOSE"
                      @click.native="closeDetailView"
                  >
                    닫기
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                    class="article-section section-0301"
                    :style="{
                    height: `${
                      isCaddieBatchInfoVisible ? `${detailViewSizeHeightOffset}%` : `92%`
                    }`,
                  }"
                >
                  <div class="section-header no-margin no-padding">
                    <div class="header-left">
                      <div class="header-title">캐디 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(caddieLength) }}건]
                      </div>
                      <ul class="header-check no-padding-horizontal">
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="caddieAttendDiv"
                                value="ALL"
                                @change="onCaddieAttendDivChanged"
                            />
                            <i></i>
                            <div class="label">전체</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="caddieAttendDiv"
                                value="ATT"
                                @change="onCaddieAttendDivChanged"
                            />
                            <i></i>
                            <div class="label">출근</div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="header-left">
                    <ul class="lookup-condition sub no-margin-horizontal" style="display: flex; flex-direction: row">
                      <li class="field caddieName">
                        <ul class="content">
                          <li class="item input">
                            <input-text
                                ref="searchValue"
                                id="searchValueInputTextBox"
                                v-model="searchValue"
                                placeholder="캐디명, 번호"
                                @keydown.enter="findCaddie"
                            />
                          </li>
                        </ul>
                      </li>
                      <ul class="content">
                        <div class="lookup-lookup">
                          <erp-button
                              buttonDiv="GET"
                              :ignore="isPopupOpened"
                              @click.native="findCaddie"
                          >
                            찾기
                          </erp-button>
                        </div>
                      </ul>
                    </ul>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                          ref="caddiesGrid"
                          v-bind="caddiesGridProps"
                          :noColumnWidth="34"
                          @keyPressed="handleCaddiesGridKeyPressed"
                          @recordClick="handleCaddieAttendGridRecordClick"
                          @rowSelected="handleCaddieAttendGridRowSelected"
                          @headerCellInfo="caddiesGridHeaderCellInfo"
                          @queryCellInfo="caddiesGridQueryCellInfo"
                          @actionComplete="caddiesGridActionComplete"
                          :isShowProgress="false"
                      />
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                    :class="[
                    'article-section',
                    'section-0302',
                    'accordion',
                    { close: !isCaddieBatchInfoVisible },
                  ]"
                    :style="{height: `${100 - detailViewSizeHeightOffset}%`}"
                >
                  <div class="section-header no-margin no-padding" style="display: flex; flex-direction: column">
<!--                    <div class="header-left">-->
<!--                      <div-->
<!--                          class="header-title"-->
<!--                          @click="-->
<!--                          isCaddieBatchInfoVisible = !isCaddieBatchInfoVisible-->
<!--                        "-->
<!--                      >-->
<!--                        배치 정보-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="header-right" style="flex-direction: column">
                      <div
                          class="section-caption"
                          v-if="isCaddieBatchInfoVisible"
                      >
                        <div class="header-inner-flexbox" v-if="isCaddieBatchInfoVisible">
                          <ul class="header-button">
                            <li class="save keyColor">
                              <erp-button
                                  buttonDiv="SAVE"
                                  :is-shortcut-button="true"
                                  :ignore="isPopupOpened"
                                  @click.native="handleSaveCaddieArrangementClick"
                              >
                                저장
                              </erp-button>
                            </li>
                            <li class="add">
                              <erp-button
                                  buttonDiv="GET"
                                  :is-custom-shortcut-button="true"
                                  :shortcut-key="'CaddieArrangement.shortcuts.get'"
                                  :shortcut="{key:'F7'}"
                                  @click.native="handleArrangementChartOpenClick"
                              >
                                출력
                              </erp-button>
                            </li>
                            <li class="cancel">
                              <erp-button
                                  buttonDiv="DELETE"
                                  @click.native="handleCancelCaddieClick"
                              >
                                취소
                              </erp-button>
                            </li>
                            <li class="addConfirm">
                              <erp-button
                                  buttonDiv="SAVE"
                                  @click.native="handleConfirmVisitorsAddedClick"
                              >
                                추가 내장확인
                              </erp-button>
                            </li>
                          </ul>
                          <ul class="lookup-button">
                            <li class="etc">
                              <ejs-dropdownbutton
                                  cssClass="lookup-button-dropdown"
                                  :items="etcMenuItems"
                                  @select="onETCShortcutMenuSelected"
                              >
                                기타
                              </ejs-dropdownbutton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                      class="section-body"
                      @click.capture="onCaddiesArrangementGridClick"
                  >
                    <ejs-grid-wrapper
                        ref="caddiesArrangementGrid"
                        v-bind="caddiesList"
                        :noColumnWidth="34"
                        :dataSource="teamInformation.caddies"
                        @actionComplete="handleCaddieArrangementGridActionComplete"
                        @batchAdd="handleCaddieArrangementGridBatchAdd"
                        @batchCancel="handleCaddieArrangementGridBatchCancel"
                        @batchDelete="handleCaddieArrangementGridBatchDelete"
                        @cellSave="handleCaddieArrangementGridCellSave"
                        @cellSaved="handleCaddieArrangementGridCellSaved"
                        @load="caddiesArrangementGridLoad"
                        @visitEjsDropdownListEditTemplateChanged="caddieArrangementGridEjsDropdownListEditTemplateChanged"
                        @headerCellInfo="caddiesArrangementGridHeaderCellInfo"
                        @queryCellInfo="caddiesArrangementGridQueryCellInfo"
                        @keyPressed="caddiesArrangementGridKeyPressed"
                        :aggregates="caddiesArrangementGridQAggregates"
                        :isShowProgress="false"
                        :isAutoSelectRow="true"
                        :isAutoSelectCell="false"
                    />
                  </div>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
      <!-- 체크인 화면 컴포넌트. 임시로 여기에 배치함-->
    </div>
    <caddie-arrangement-search-name-popup
      v-if="iscaddieArrangementSearchNamePopupOpened"
      ref="caddie-arrangement-search-name-popup"
      :selectedBsnDate="bsnDateValueModel"
      :selectedPartDiv="partDiv"
      @close="handleCaddieArrangementSearchNamePopupClose"
      @confirm="handleConfirmSelectInSearchResults"
    />
    <caddie-arrangement-hole-div-popup
      v-if="isCaddieArrangementHoleDivPopupOpened"
      ref="caddie-arrangement-hole-div-popup"
      @close="handleCaddieArrangementHoleDivPopupClose"
    />
    <caddie-arrangement-notice-popup
      v-if="isCaddieArrangementNoticePopupOpened"
      ref="caddie-arrangement-notice-popup"
      @close="handleCaddieArrangementNoticePopupClose"
    />
    <caddie-arrangement-print-popup
      v-if="isCaddieArrangementPrintPopupOpened"
      ref="caddie-arrangement-print-popup"
      @close="handleCaddieArrangementPrintPopupClose"
    />
    <reservation-time-detail-popup
      v-if="isReservationTimeDetailPopupOpen"
      ref="reservationTimeDetailPopup"
      @popupClosed="handleReservationTimeDetailPopupClosed"
    />
    <add-voc-popup
      v-if="isAddVocPopupOpen"
      ref="add-voc-popup"
      @open="handleAddVocPopupOpen"
      @popupClosed="handleAddVocPopupClose"
      @popupConfirmed="handleAddVocPopupConfirm"
    />
    <built-in-guest-notification-talk-send-popup
        v-if="isBuiltInGuestNotificationTalkSendPopupOpened"
        ref="builtInGuestNotificationTalkSendPopup"
        :visit-date="teamInformation.visitDate"
        :chkin-id="teamInformation.checkInId"
        :grp-no="teamInformation.grpNo"
        @popupClosed="builtInGuestNotificationTalkSendPopupClosed"
    />
    <caddieFeePopup
        v-if="isCaddieFeePopupOpen"
        ref="caddieFeePopup"
        @popupClosed="caddieFeePopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.subdivision .content .item {width: 90px;}
body .appContent .lookup-condition .field.builtinNameCaddySearch .content .item.input {width: 90px;}
body .appContent .lookup-condition .field.automaticInquiry .content .item.input {width: 60px;}

body .appContent .body-article .article-left,
body .appContent .body-article .article-right {transition: all 0.3s ease-out;}
body .appContent .body-article .article-left.active {width: 100%;}
body .appContent .body-article .article-left:not(.active) {width: calc(100% - 509px);}
body .appContent .body-article .article-right {width: 509px;}

body .section-header .header-right {display: flex;}
body .section-caption .caption-button {display: flex; flex: 1; justify-content: flex-end;}

body .section-body {overflow: hidden;}

body .body-article .body-article .section-header .header-button li >>> .e-btn {width: 78px;}
body .body-article .body-article .section-header .header-button .cancel >>> .e-btn {width: 55px;}
body .body-article .body-article .section-header .header-button .addConfirm >>> .e-btn {width: 100px;}
body .body-article .body-article .section-header .header-button li.save.keyColor >>> .e-btn {color: #fff; background-color: #2e7458;}

body .appContent .article-section.section-01 .body-box {width: 164px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .body-box {width: 229px;}
}

body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 164px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}
}

body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 229px;}
}

body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 485px;}
}

body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .body-box,
body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .body-box,
  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .body-box {width: 229px;}
}

body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,
body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,
  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box {width: 229px;}
}

body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box,
body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box,
  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box {width: 263px;}
}

body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box,
body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 229px;}
@media (min-width: 1920px) {
  body .wrapper.dev-layout-left-no-right-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box,
  body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box {width: 584px;}
}

body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body .body-box,
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body .body-box.dev-reservation-active {flex: none; width: calc(100% + 2px) !important;}
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box,
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-4 .body-box.dev-reservation-active {flex: none; width: calc((100% / 4) + 2px) !important;}
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box,
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-3 .body-box.dev-reservation-active {flex: none; width: calc((100% / 3) + 2px) !important;}
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box,
body .appContent .body-article.dev-caddie-arrangement-view-fixed .article-section.section-01 .section-body.dev-reservation-course-num-2 .body-box.dev-reservation-active {flex: none; width: calc((100% / 2) + 2px) !important;}

body .appContent .article-section.padding-0 {padding-left: 0; padding-right: 0;}
body .appContent .article-section.section-01 {height: calc(100% - 47px);}
body .appContent .article-section.section-01 .section-body {display: flex; flex-direction: row; overflow: hidden; padding: 0 1px; border: none;}
body .appContent .article-section.section-01 .section-body:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-box {overflow: hidden; float: left; height: 100%; margin: 0 -1px;}
body .appContent .article-section.section-01 .body-grid {height: calc(100% - 24px);}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib {border-bottom-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib .e-gridheader {border-top-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-header {box-sizing: border-box; height: 24px; padding: 3px 8px 2px 8px; border: 1px solid #ccc; border-bottom: none; background-color: #f9f9f9; color: #000; text-align: center;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active {flex: 1; width: auto !important; z-index: 10;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-grid >>> .e-grid.e-lib {border-left-color: #000; border-right-color: #000; border-bottom-color: #000;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-grid >>> .dev-reservation-add-mode-row .e-rowcell:first-child {background-image: url('../../../assets/images/common/focus-strong.png'); background-repeat: no-repeat; background-position: left top;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .e-grid.e-lib {border-bottom-color: #000;}
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-header,
body .appContent .article-section.section-01 .body-box.dev-reservation-active .body-footer {border-color: #000;}

body .appContent .article-section.section-02 {height: 47px;}
body .appContent .article-section.section-02.accordion .header-title {background-image: url('../../../assets/images/common/accordion-bottom.png');}
body .appContent .article-section.section-02.accordion.close .header-title {background-image: url('../../../assets/images/common/accordion-top.png');}

body .appContent .article-section.section-02 .section-body {overflow: visible; border: none;}
body .appContent .article-section.section-02 .body-card {overflow-x: scroll; overflow-y: hidden; height: 100%; border: 1px solid #ccc;}
body .appContent .article-section.section-02 .body-card .box {display: block; list-style: none; padding: 0px; margin: 0;}
@supports (display: flow-root) {
  body .appContent .article-section.section-02 .body-card .box {display: flow-root;}
}
body .appContent .article-section.section-02 .body-card .box:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-02 .body-card .card {display: block; box-sizing: border-box; float: left; width: 240px; height: 131px; padding: 9px; margin: 6px 0 6px 6px; border: 1px solid #ccc; border-radius: 3px; background-color: #f9f9f9;}
@supports (display: flow-root) {
  body .appContent .article-section.section-02 .body-card .card {display: flow-root;}
}
body .appContent .article-section.section-02 .body-card .card:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-02 .body-card .photo {box-sizing: border-box; float: left; width: 82px; height: 100%; border: 1px solid #e0e0e0; background: #fff url('../../../assets/images/common/photo-default.png') no-repeat center center; background-size: 40%;}
body .appContent .article-section.section-02 .body-card .photo .item {width: 100%; height: 100%; background-color: #fff; background-repeat: no-repeat; background-position: center center; background-size: contain;}
body .appContent .article-section.section-02 .body-card .content {box-sizing: border-box; list-style: none; float: left; width: calc(100% - 82px); padding: 5px 0 5px 12px; margin: 0;}
body .appContent .article-section.section-02 .body-card .content .item {overflow: hidden; width: 100%; min-height: 18px; text-overflow: ellipsis; white-space: nowrap;}
body .appContent .article-section.section-02 .body-card .content .item.hole {text-decoration: underline; cursor: pointer;}
body .appContent .article-section.section-02 .body-card .content .item.dev-hole__changed {color: #f82f2f;}

body .appContent .body-article.dev-body-article-active-team__information .article-section.section-01 {height: calc(100% - 210px);}
body .appContent .body-article.dev-body-article-active-team__information .article-section.section-02 {height: 208px;}

body .appContent .article-section.section-0301 .section-body {overflow: hidden; border: none;}
body .appContent .article-section.section-0301 .section-header .lookup-condition.sub {margin-right: 3px;}
body .appContent .article-section.section-0301 .section-header .header-right .field.caddieName .input {width: 110px;}

body .appContent .article-section.section-0302 {height: 197px;}
body .appContent .article-section.section-0302 .section-body {border: none;}
body .appContent .article-section.section-0302 .section-body .body-grid {height: 100%;}
body .appContent .article-section.section-0302 .section-caption {border: none;}
/*@supports (display: flow-root) {body .appContent .article-section.section-0302 .section-caption {display: flex; justify-content: flex-end;}}*/
/*body .appContent .article-section.section-0302 .section-caption:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}*/
/*body .appContent .article-section.section-0302 .section-caption .caption-input {display: block; list-style: none; float: left; padding: 9px 12px; margin: 0;}*/
/*@supports (display: flow-root) {body .appContent .article-section.section-0302 .section-caption .caption-input {display: flow-root;}}*/
/*body .appContent .article-section.section-0302 .section-caption .caption-input:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}*/
body .appContent .article-section.section-0302 .section-caption {margin-top: 3px;}
body .appContent .article-section.section-0302 .section-caption .caption-input .title {float: left; padding: 2px 6px 1px 6px; color: #000;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input {float: left; width: 85px; padding: 0 6px 0 0;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input .e-input {box-sizing: border-box; text-align: center; padding: 1px 6px 0 6px; margin: 0; border: 1px solid #e0e0e0; border-radius: 3px; background-color: #fff; color: #666; font-size: 12px; font-family: '돋움', Dotum, Arial, Verdana, sans-serif;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input .e-input:focus {border-color: #999 !important;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input .e-input::selection {background-color: #ecfaf0; color: #666;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input .e-input::placeholder {color: #bbb;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input .e-input:disabled {background-color: #f0f0f0 !important; color: #bbb;}
body .appContent .article-section.section-0302 .section-caption .caption-input .input .e-input.e-disabled {background-color: #f0f0f0 !important; color: #bbb;}
body .appContent .article-section.section-0302 .section-caption .caption-button {float: left; border-bottom: none; margin-left: -18px;}

body .appContent .article-section.section-030201 .section-body {overflow: hidden; border: none;}

.no-margin {margin: 0px !important;}
.no-padding {padding: 0px !important;}
.no-padding-horizontal {padding-left: 0px !important; padding-right: 0px !important;}
.no-margin-horizontal {margin-left: 0px !important; margin-right: 0px !important;}
.header-inner-flexbox {display: flex !important; flex-direction: row !important;; justify-content: flex-end !important;}
.arrange .e-btn {width: 20px}
body .section-body >>> .mandate {background: url("../../../assets/images/common/mandate_icon.png") no-repeat  ; width: 24px; height: 15px; position: absolute; right: 3px; top: 0; bottom: 0; margin: auto}
.select-flex{flex: 1 !important;}
</style>

<script>
import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import {Aggregate, Edit, ForeignKey, Resize} from '@syncfusion/ej2-vue-grids';
import ErpButton from "@/components/button/ErpButton.vue";
import {
  getCaddieArgmtInfoDetaill,
  getCaddieArgmtInfoList, getCaddieArrangementNotice, getCaddieArrangementPrint,
  getCaddieFee,
  putCaddieArgmtInfo,
  updateAddVisitConfirmStateOK,
} from '@/api/caddieArgmt';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetColorValue,
  commonCodesGetCommonCode, commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetCommonCodeByIdx,
  commonCodesGetComName,
  commonCodesGetStandardInfo,
} from '@/utils/commonCodes';
import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDate, getTodayDateTime,
  getTodayNavigationDate,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';

import CaddieArrangementHoleDivPopup from './CaddieArrangementHoleDivPopup';
import CaddieArrangementNoticePopup from './CaddieArrangementNoticePopup';
import CaddieArrangementPrintPopup from './CaddieArrangementPrintPopup';
import CaddieArrangementSearchNamePopup from './CaddieArrangementSearchNamePopup';
import ReservationTimeDetailPopup from '@/views/checkin/popup/ReservationTimeDetailPopup';
import BuiltInGuestNotificationTalkSendPopup from '@/views/common/BuiltInGuestNotificationTalkSendPopup';

import AddVocPopup from '@/views/game-progress/popup/AddVocPopup';
import {mapActions} from 'vuex/dist/vuex.esm.browser';
import {mapMutations} from "vuex";
import {setGridUneditKeydownProcess} from '@/utils/gridUtil';
import InputDate from '@/components/common/datetime/InputDate';
import EjsGridWrapper from '@/components/common/EjsGridWrapper';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import InputText from '@/components/common/text/InputText';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {openNewWindow} from '@/utils/appInfo';
import {addShortcut, keypressEventEqualsShortcut, removeShortcut, SHORTCUT_KEYS} from '@/utils/KeyboardUtil';
import gridVisitEjsDropdownlistEditTemplate
  from '@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate';
import BillPrintUtil from '@/utils/billPrintUtil';
import { memberNoFormatter } from "@/utils/formatter";
import { getDeviceInfo } from "@/utils/device";
import {createAttendInfo} from "@/api/caddieTARegistration";
import caddieFeePopup from "@/views/checkin/popup/CaddieFeePopup.vue";

const SHORTCUT_FOCUS_BUILTIN_NAME_AND_CADDIE = {
  key: 'CaddieArrangement.shortcuts.builtinNameCaddy',
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.Insert,
  },
};

const SHORTCUT_FOCUS_ADD_VISIT_CONFIRM = {
  key: 'CaddieArrangement.shortcuts.addVisitConfirm',
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F3,
  },
};

export default {
  name: 'CaddieArrangement',
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    caddieFeePopup,
    InputText,
    EjsGridWrapper,
    InputDate,
    CaddieArrangementHoleDivPopup,
    CaddieArrangementNoticePopup,
    CaddieArrangementPrintPopup,
    CaddieArrangementSearchNamePopup,
    ReservationTimeDetailPopup,
    AddVocPopup,
    BuiltInGuestNotificationTalkSendPopup,
    ErpButton
  },
  async created() {
    const todayDate = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);
    this.bsnDate = moment(todayDate, 'YYYY-MM-DD').toDate();
    if (this.bsnDate) {
      const {data} = await GolfErpAPI.fetchCalenderInfo(
          this.bsnDateValueModel,
          false,
      );
      this.bsnDateInfo.bsnCode = data.bsnCode;
      this.bsnDateInfo.dwCode = data.dwCode;
    }
    this.courseDivOptions = commonCodesGetCommonCode("COURSE_CODE", true);
    this.courseDivOptions.unshift({ comCode: "%", comName: "전체" });
    this.courseDiv = this.courseDivOptions.map(x => x.comCode);
    await this.refreshAll();

    this.$EventBus.$on(
        'updateChangeHoleDiv',
        async () => await this.refreshTeamInformation(),
    );

    addShortcut(
        SHORTCUT_FOCUS_BUILTIN_NAME_AND_CADDIE.key,
        SHORTCUT_FOCUS_BUILTIN_NAME_AND_CADDIE.shortcut,
    );
    addShortcut(
        SHORTCUT_FOCUS_ADD_VISIT_CONFIRM.key,
        SHORTCUT_FOCUS_ADD_VISIT_CONFIRM.shortcut,
    );
    window.addEventListener('keydown', this.onWindowKeypress, true);
    document.onkeydown = async (event) => {
      if ((event.which || event.keyCode) === 116) {
        if (this.isTeamInfoAndCaddiesInfoListIsChanged()) {
          event.preventDefault();
          if (await this.confirm('수정 내역이 있습니다. 진행하시겠습니까?')) {
            location.reload();
          }
        }
      }
    };

    this.$EventBus.$on("memoConfirm", (args) => {
      this.memoConfirm(args);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("memoConfirm");
    this.$EventBus.$off("updateChangeHoleDiv");
    if (this.autoInquiryTimer) {
      clearInterval(this.autoInquiryTimer);
    }

    window.removeEventListener('keydown', this.onWindowKeypress, true);
    document.onkeydown = null;
    removeShortcut(SHORTCUT_FOCUS_BUILTIN_NAME_AND_CADDIE.key);
    removeShortcut(SHORTCUT_FOCUS_ADD_VISIT_CONFIRM.key);
  },
  destroyed() {
  },
  watch: {
    autoInquiryFlag(autoInquiryFlag) {
      if (!autoInquiryFlag) {
        this.clearAutoInquiry();
      } else {
        this.setAutoInquiry();
      }
    },
    courseDiv(n, o) {
      if(this.selectedCourseDiv === '%' && o.length && !n.includes('%')) {
        this.courseDiv = [];
        this.selectedCourseDiv = null;
        this.errorToast("코스 구분을 한 개 이상 선택하여 주십시오.");
        return;
      }
      if (this.selectedCourseDiv === '%' && n.includes('%')) {
        this.courseDiv = this.courseDivOptions.map(x => x.comCode);
        this.selectedCourseDiv = null;
        return;
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isTeamInfoAndCaddiesInfoListIsChanged()) {
      if (
          !this.isReservationDetailModifyMode &&
          !(await this.confirm('수정 내역이 있습니다. 진행하시겠습니까?'))
      ) {
        return;
      }
    }
    next();
  },
  data() {
    return {
      //Right Layout View 때문에 고정값 들어가야함. Percent 안됨.
      detailViewSizeWidthOffset: '275px',
      detailViewSizeHeightOffset: 70,
      gridEjsDropdownlistEditTemplateEventBus: new Vue(),
      caddieLength: 0,
      bsnDate: moment().toDate(),
      partDiv: '',
      query: '',
      searchValue: '',
      notArrangementTimeFlag: false,
      rainCancelTimeFlag: true,
      isTimeListLineOption: false,
      isCaddieFeePopupOpen: false,
      caddieAttendDiv: 'ATT',
      commonCodeFields: { text: "comName", value: "comCode" },
      courseDiv: [],
      courseDivOptions: null,
      selectedCourseDiv: null,

      courseReserveTimeActiveIndex: 0,
      founds: [][Symbol.iterator](),
      displayFounds: false,

      selectedCaddie: {},
      selectedRow: null,

      visibleArticleRight: false,
      visibleTeamInformation: true,
      isCaddieBatchInfoVisible: true,

      iscaddieArrangementSearchNamePopupOpened: false,
      isCaddieArrangementHoleDivPopupOpened: false,
      isCaddieArrangementNoticePopupOpened: false,
      isCaddieArrangementPrintPopupOpened: false,
      isReservationTimeDetailPopupOpen: false,
      isAddVocPopupOpen: false,
      isBuiltInGuestNotificationTalkSendPopupOpened: false,

      reservationsAndCaddies: {
        courses: [],
        caddies: [],
      },
      teamInformation: {
        checkInId: '',
        visitors: [],
        caddies: [],

        timeId: '',
        resveId: '',
        timeStatus: '',
        partDiv: '',
        startTime: '',
        startCourse: '',
        holeDiv: '',
        resveName: '',
        resveKind: '',
        resveChannel: '',
        grpNo: '',
        GrpKind: '',
        GrpName: '',
        memoFlag: '',
        visitConfirmFlag: '',
        caddieFlag: '',
        optFlag: '',
      },
      originalCaddies: [],

      caddiesList: {
        allowSorting: false,
        allowFiltering: false,
        columns: [
          {
            allowEditing: false,
            field: 'caddieName',
            headerText: '캐디명',
            minWidth: 16,
            textAlign: 'Left',
            type: 'string',
            width: 70,
          },
          {
            allowEditing: true,
            field: 'cartNo',
            headerText: '카트',
            minWidth: 16,
            textAlign: 'Center',
            type: 'string',
            width: 70,
          },
          {
            field: 'caddieId',
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: 'snoMoveFlag',
            visible: false,
          },
          {
            allowEditing: false,
            field: 'caddieNo',
            headerText: '번호',
            minWidth: 16,
            textAlign: 'Center',
            type: 'number',
            width: 50,
          },
          {
            groupCode: 'CADDIE_ARGMT_DIV',
            field: 'caddieArgmtDiv',
            headerText: '구분',
            minWidth: 16,
            width: 60,
            type: 'string',
            textAlign: 'Left',
            editTemplate: () =>
                gridVisitEjsDropdownlistEditTemplate(
                    'caddieArgmtDiv',
                    commonCodesGetCommonCode('CADDIE_ARGMT_DIV'),
                    this.$refs.caddiesArrangementGrid,
                    this.gridEjsDropdownlistEditTemplateEventBus,
                ),
            valueAccessor: (field, data) => {
              const commonCode = commonCodesGetCommonCode(
                  'CADDIE_ARGMT_DIV',
              ).find((commonCode) => commonCode.comCode === data[field]);
              if (commonCode) {
                return commonCode.comName;
              } else {
                return null;
              }
            },
          },
          {
            groupCode: 'CADDIE_BAG_DIV',
            field: 'caddieBagDiv',
            headerText: 'Bag',
            minWidth: 16,
            width: 50,
            type: 'string',
            textAlign: 'Center',
            editTemplate: () =>
                gridVisitEjsDropdownlistEditTemplate(
                    'caddieBagDiv',
                    commonCodesGetCommonCode('CADDIE_BAG_DIV'),
                    this.$refs.caddiesArrangementGrid,
                    this.gridEjsDropdownlistEditTemplateEventBus,
                ),
            valueAccessor: (field, data) => {
              const commonCode = commonCodesGetCommonCode(
                  'CADDIE_BAG_DIV',
              ).find((commonCode) => commonCode.comCode === data[field]);
              if (commonCode) {
                return commonCode.comName;
              } else {
                return null;
              }
            },
          },
          {
            allowEditing: true,
            field: 'caddieFee',
            headerText: '캐디피',
            minWidth: 16,
            textAlign: 'Right',
            type: 'string',
            isNumericType: true,
            width: 80,
          },
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: 'Batch',
          showConfirmDialog: false,
          newRowPosition: 'Bottom',
        },
        isNOColumnDisplay: true,
        noColumnWidth: 30,
        provides: [Edit, Resize, ForeignKey, Aggregate],
        selectionSettings: {type: 'Multiple', enableToggle: false},
      },

      autoInquiryFlag: false,
      autoInquirySecond: 10,
      autoInquiryTimer: null,
      autoInquiryRemainingSeconds: 0,

      caddieNo: '',
      bsnDateInfo: {
        bsnCode: null,
        dwCode: null,
      },

      isDetailViewOpened: true,
      isCaddieArrangementViewFixed: true, // 고정 여부
    };
  },
  methods: {
    ...mapMutations([
      'SET_IS_RIGHT_VOC_ACTIVE',
      'SET_IS_LEFT_MENU_ACTIVE'
    ]),
    ...mapActions('memoView', ['clearMemo', 'setResveId']),
    numberWithCommas,
    commonCodesGetColorValue,
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    memberNoFormatter,
    handleDataBound() {
      // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경. 그로인해 clearMemo 주석처리.
      // this.clearMemo();
    },
    onWindowKeypress(event) {
      if (this.isPopupOpened) {
        return;
      }
      if (
          keypressEventEqualsShortcut(
              event,
              SHORTCUT_FOCUS_BUILTIN_NAME_AND_CADDIE.shortcut,
          )
      ) {
        this.$refs['builtinNameCaddy'].focus();
      }
      if (
          keypressEventEqualsShortcut(
              event,
              SHORTCUT_FOCUS_ADD_VISIT_CONFIRM.shortcut,
          )
      ) {
        this.handleConfirmVisitorsAddedClick();
      }
    },

    // event handlers
    onShortcutMenuSelected(args) {
      switch (args.item.id) {
        case 1:
          // eslint-disable-next-line no-case-declarations
          let routeData = this.$router.resolve({
            name: 'CaddieTARegistration',
            query: {
              bsnDate: this.bsnDateValueModel,
            },
          });
          openNewWindow(routeData);
          break;
        case 2:
          this.handleAddVocPopupOpen();
          break;
      }
    },
    handleQueryFocus() {
      this.clearFound();
    },
    handleQueryKeydownEnter() {
      this.focusFoundNext();
    },
    async handleFetchClick() {
      if (this.isTeamInfoAndCaddiesInfoListIsChanged()) {
        if (!(await this.confirm('수정 내역이 있습니다. 진행하시겠습니까?'))) {
          return;
        }
        this.$refs.caddiesArrangementGrid.refresh();
      }
      await this.refreshReservationsAndCaddies();
      await this.refreshTeamInformation();

      // 일마감 여부 확인.
      await GolfErpAPI.fetchCloseCheck({
        bsnDateFrom: this.bsnDateValueModel,
        bsnDateTo: this.bsnDateValueModel,
        storeCode: 'FRONT',
      });
    },
    async fastVocMove(args) {
      const {
        visitName:memberName,
        membershipId:id
      } = args;
      await this.SET_IS_LEFT_MENU_ACTIVE(false);
      await this.SET_IS_RIGHT_VOC_ACTIVE(true);
      await this.$EventBus.$emit('vocButtonClick');
      this.$nextTick(() => {
        this.$EventBus.$emit("VOC",{id,memberName});
      });
    },
    handleSearchClick() {
      this.handleCaddieArrangementSearchNamePopupOpen();
    },
    handleReserveTimeDivClick($event, {courseReserveTimeIdx}) {
      this.courseReserveTimeActiveIndex = courseReserveTimeIdx;
    },
    async handleCourseReserveTimeGridRecordClick(args) {
      const {
        rowData,
        column: {field},
      } = args;
      if (field === 'resveName') {
        await this.openReservationTimeDetailPopup(rowData);
      }
      this.selectedRow = rowData;
      await this.fetchTeamInformationAndMemo(rowData, field);
    },
    async openReservationTimeDetailPopup(rowData) {
      const {
        resveNo,
        timeId,
        resveName,
      } = rowData;
      if (!resveNo || !timeId || !resveName) {
        return;
      }
      this.isReservationTimeDetailPopupOpen = true;
      await this.$nextTick();
      await this.$refs.reservationTimeDetailPopup.showReservationTimeDetailPopup(
        resveNo,
        timeId
      );
    },
    async fetchTeamInformationAndMemo(rowData) {
      if (this.isTeamInfoAndCaddiesInfoListIsChanged()) {
        if (!(await this.confirm('수정 내역이 있습니다. 진행하시겠습니까?'))) {
          return;
        }
      }

      const {
        chkinId,
        timeId,
        resveId,
        timeStatus,
        partDiv,
        startTime,
        startCourse,
        holeDiv,
        resveName,
        resveKind,
        resveChannel,
        grpNo,
        GrpKind,
        GrpName,
        memoFlag,
        visitConfirmFlag,
        caddieFlag,
        optFlag,
        visitDate,
        dwCode,
      } = rowData;
      const courseCode = this.reservationsAndCaddies.courses[
          this.courseReserveTimeActiveIndex
          ].courseCode;

      if ((timeStatus === 'RAIN_TEAM' && !chkinId) || !chkinId) {
        this.clearTeamInformation();
      } else {
        this.fetchTeamInformation(chkinId).then(
            ({checkInId, visitors, caddies}) => {
              this.teamInformation = {
                visitDate,
                courseCode,
                checkInId,
                visitors,
                caddies,
                timeId,
                resveId,
                timeStatus,
                partDiv,
                startTime,
                startCourse,
                holeDiv,
                resveName,
                resveKind,
                resveChannel,
                grpNo,
                GrpKind,
                GrpName,
                memoFlag,
                visitConfirmFlag,
                caddieFlag,
                optFlag,
                dwCode,
              };
              this.originalCaddies = this.teamInformation.caddies;
            },
        );

        this.isDetailViewOpened = true;
      }
      if(!resveId){
        return;
      }
      this.setResveId(resveId);
      if(memoFlag){
        this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
      }
    },
    // handleCourseReserveTimeGridRowSelected(args) {
    //   // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
    //   const resveId = args.data.resveId;
    //   if (resveId) {
    //     this.setResveId(resveId);
    //   }
    // },
    // handleCourseReserveTimeGridRowSelecting(args) {
    //   const {
    //     data,
    //   } = args;
    //   this.fetchTeamInformationAndMemo(data);
    // },
    handleTeamInformationHeaderClick() {
      this.collapseTeamInformation();
    },
    handleGridKeyPressed(args) {
      if (args.key.toLowerCase() === 'enter' && this.displayFounds) {
        args.cancel = true;
        this.focusFoundNext();
      }
    },
    handleCaddieAttendGridRecordClick({rowData, column: {field}}) {
      this.notSelectTeamWarnToast();

      if(field === "caddieName"){
        const row = JSON.parse(JSON.stringify(rowData));
        row.snoMoveFlag = true;

        this.addCaddieArrangement(row);
      }
    },
    handleCaddieAttendGridRowSelected({data}) {
      this.selectedCaddie = data;
    },
    unpauseAutoInquiry() {
      if (this.autoInquiryFlag) {
        this.setAutoInquiry();
      }
    },
    pauseAutoInquiry() {
      if (this.autoInquiryFlag) {
        this.clearAutoInquiry();
      }
    },
    handleCancelCaddieClick() {
      this.notSelectTeamWarnToast();

      const gridRef = this.$refs['caddiesArrangementGrid'];
      if (gridRef.getSelectedRecords().length === 0) {
        gridRef.selectRow(0);
      }

      if (gridRef.getSelectedRecords().length !== 0) {
        gridRef.deleteRecord();
      }
    },
    handleArrangementChartOpenClick() {
      this.notSelectTeamWarnToast();
      this.handleCaddieArrangementPrintPopupOpen();
    },
    async handleConfirmVisitorsAddedClick() {
      if (this.teamInformation.timeStatus !== "ADD_VISIT_TEAM") {
        this.errorToast("추가 내장객 확인 가능한 팀이 아닙니다");
        return;
      }

      if (!(await this.confirm('추가 내장객 확인 처리하시겠습니까?'))) {
        return;
      }

      if (this.commonMixinIsButtonDisableByAuth('gameProgressCaddiArrangeGet')){
        this.errorToast("권한을 확인하여 주십시오");
        return;
      }

      this.updateAddVisitConfirmStateOK().then(() => {
        this.infoToast('처리되었습니다');
        this.refreshAll();
      });
    },
    handleArrangementNoticeClick() {
      this.notSelectTeamWarnToast();

      this.handleCaddieArrangementNoticePopupOpen();
    },
    async handleSaveCaddieArrangementClick() {
      this.notSelectTeamWarnToast();

      await this.putCaddieArgmtInfo();
    },
    async handleCaddieArrangementGridActionComplete({requestType}) {
      if (requestType === 'refresh') {
        this.unpauseAutoInquiry();
        // 예약타임 상세 조회 팝업 오픈시 포커스 뒤로 빠지는 현상 임시 해결책
        if (this.isReservationTimeDetailPopupOpen) {
          await this.$nextTick();
          const $el = document.querySelector("#closeButton");
          if ($el) {
            setTimeout(function () {
              document.activeElement.blur();
              $el.focus();
            });
          }
        }
      }
    },
    handleCaddieArrangementGridBatchAdd() {
      this.pauseAutoInquiry();
    },
    handleCaddieArrangementGridBatchCancel() {
      this.unpauseAutoInquiry();
    },
    handleCaddieArrangementGridBatchDelete() {
      if (this.checkIsModifyingNow()) {
        this.pauseAutoInquiry();
      } else {
        this.unpauseAutoInquiry();
      }
    },
    closeDetailView() {
      this.isDetailViewOpened = !this.isDetailViewOpened;
    },
    caddieArrangementGridEjsDropdownListEditTemplateChanged(args) {
      const {columnName, rowData, value} = args;
      let {_rid, caddieArgmtDiv, caddieBagDiv, caddieFeeDiv} = rowData;

      // TODO : rowData의 값은 현재 변경된 값이 아닌 이전 값을 가져오고 있어 캐디피 계산이 잘못되고 있음.
      // TODO : value의 값이 현재값이라 columnName에 따라 value값을 셋팅해주도록 변경함.
      if (columnName === 'caddieArgmtDiv') {
        caddieArgmtDiv = value;
      } else if (columnName === 'caddieBagDiv') {
        caddieBagDiv = value;
      }

      if (columnName === 'caddieArgmtDiv' || columnName === 'caddieBagDiv') {
        const {holeDiv} = this.teamInformation;

        this.getCaddieFee(
            caddieArgmtDiv,
            caddieBagDiv,
            caddieFeeDiv,
            holeDiv,
        ).then(({caddieFee}) => {
          const gridRef = this.$refs['caddiesArrangementGrid'];
          const currentViewRecords = gridRef.getBatchCurrentViewRecords();

          const targetIndex = currentViewRecords.findIndex(
              ({_rid: __rid}) => __rid === _rid,
          );
          if (0 <= targetIndex) {
            gridRef.updateCell(targetIndex, 'caddieFee', caddieFee);
          }
        });
      }
    },
    handleCaddieArrangementGridCellSave(args) {
      switch (args.columnName) {
        case 'caddieArgmtDiv':
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
        case 'caddieBagDiv':
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
      }
    },
    setEjsDropdonwlistEditTemplateValue(args) {
      args.value = args.cell.firstElementChild.getElementsByClassName(
          'e-dropdownlist',
      )[0].ej2_instances[0].itemData.comCode;
    },
    handleCaddieArrangementGridCellSaved(args) {
      if (!args.cancel) {
        if (this.checkIsModifyingNow()) {
          this.pauseAutoInquiry();
        } else {
          this.unpauseAutoInquiry();
        }
      }
    },
    checkIsModifyingNow() {
      const gridInstance = this.$refs.caddiesArrangementGrid;
      if (gridInstance) {
        const {addedRecords, changedRecords, deletedRecords} = gridInstance.getBatchChanges();
        return (
            addedRecords.length !== 0 ||
            changedRecords.length !== 0 ||
            deletedRecords.length !== 0
        );
      } else {
        return false;
      }
    },
    handleCaddieArrangementSearchNamePopupOpen() {
      this.iscaddieArrangementSearchNamePopupOpened = true;
      if (this.autoInquiryFlag) {
        this.clearAutoInquiry();
      }

      this.$nextTick(() => {
        this.$refs['caddie-arrangement-search-name-popup'].showDialog();
      });
    },
    handleCaddieArrangementSearchNamePopupClose() {
      if (this.autoInquiryFlag) {
        this.setAutoInquiry();
      }
      this.iscaddieArrangementSearchNamePopupOpened = false;
    },
    handleConfirmSelectInSearchResults({data}) {
      const {startCourse, timeId} = data;

      this.findAndSelectRowAsCourseCodeAndTimeId(startCourse, timeId);
    },
    handleCaddieArrangementHoleDivPopupOpen(visitor) {
      this.isCaddieArrangementHoleDivPopupOpened = true;
      if (this.autoInquiryFlag) {
        this.clearAutoInquiry();
      }
      this.$nextTick(() => {
        this.$refs['caddie-arrangement-hole-div-popup'].showDialog(
          visitor,
          this.teamInformation.visitDate,
        );
      });
    },
    handleCaddieArrangementHoleDivPopupClose() {
      if (this.autoInquiryFlag) {
        this.setAutoInquiry();
      }
      this.isCaddieArrangementHoleDivPopupOpened = false;

      this.$refs['teamInfoLayer']?.refresh();
    },
    handleCaddieArrangementNoticePopupOpen() {
      this.isCaddieArrangementNoticePopupOpened = true;
      if (this.autoInquiryFlag) {
        this.clearAutoInquiry();
      }

      this.$nextTick(() => {
        this.$refs['caddie-arrangement-notice-popup'].showDialog();
      });
    },
    handleCaddieArrangementNoticePopupClose() {
      if (this.autoInquiryFlag) {
        this.setAutoInquiry();
      }
      this.isCaddieArrangementNoticePopupOpened = false;
    },
    handleCaddieArrangementPrintPopupOpen() {
      this.isCaddieArrangementPrintPopupOpened = true;
      if (this.autoInquiryFlag) {
        this.clearAutoInquiry();
      }

      this.$nextTick(() => {
        this.$refs['caddie-arrangement-print-popup'].showDialog(
          this.teamInformation.checkInId,
          this.selectedRow.grpName,
        );
      });
    },
    handleCaddieArrangementPrintPopupClose() {
      if (this.autoInquiryFlag) {
        this.setAutoInquiry();
      }
      this.isCaddieArrangementPrintPopupOpened = false;
    },
    handleReservationTimeDetailPopupClosed() {
      this.isReservationTimeDetailPopupOpen = false;
    },
    async handleAddVocPopupOpen() {
      this.notSelectTeamWarnToast();
      this.isAddVocPopupOpen = true;
      await this.$nextTick();
      const gridInstance = this.$refs['caddiesArrangementGrid'];
      const currentSelectedRow = gridInstance.getSelectedRowIndexes();
      if (currentSelectedRow.length < 1) {
        this.errorToast('배치된 캐디를 선택해주세요');
        return;
      }
      const {argmtId} = gridInstance.getBatchCurrentViewRecords()[currentSelectedRow[0]];
      const {
        checkInId,
        startTime,
        startCourse,
      } = this.teamInformation;
      this.$refs['add-voc-popup'].showPopup(this.bsnDateValueModel, argmtId, checkInId, startTime, startCourse);
    },
    handleAddVocPopupClose() {
      this.isAddVocPopupOpen = false;
    },
    handleAddVocPopupConfirm() {
      this.handleAddVocPopupClose();
    },
    handleHoleDivClick(visitor) {
      this.handleCaddieArrangementHoleDivPopupOpen(visitor);
    },

    // features
    clearFound() {
      this.$refs['coursesGrids'].some((grid) => {
        grid.getDataRows().some(({cells}) => {
          Array.prototype.some.call(cells, (cell) => {
            cell.classList.remove(this.$t('className.grid.cellFound'));
          });
        });
      });
      this.founds = [][Symbol.iterator]();
      this.displayFounds = false;
      this.query = '';
    },
    setAutoInquiry() {
      if (this.autoInquiryTimer) {
        return;
      }

      this.autoInquiryRemainingSeconds = this.autoInquirySecond - 1;
      this.autoInquiryTimer = setInterval(() => {
        if (this.autoInquiryRemainingSeconds === 0) {
          this.autoInquiryRemainingSeconds = this.autoInquirySecond;

          this.refreshAll();
          this.$refs['teamInfoLayer']?.refresh();
        }

        this.autoInquiryRemainingSeconds -= 1;
      }, 1000);
    },
    clearAutoInquiry() {
      clearInterval(this.autoInquiryTimer);
      this.autoInquiryTimer = null;
    },
    async addCaddieArrangement(caddieInfo) {
      if (!caddieInfo) {
        this.errorToast(
            `입력하신 캐디번호(${this.caddieNo}) 캐디를 찾을 수 없습니다.`,
        );
      } else {
        const gridRef = this.$refs['caddiesArrangementGrid'];
        const {holeDiv} = this.teamInformation;
        const {caddieId, caddieFeeDiv} = caddieInfo;

        const defaultCaddieArgmtDiv = commonCodeGetComCodeDefaultValue(
            'CADDIE_ARGMT_DIV',
        );
        const defaultCaddieBagDiv = commonCodeGetComCodeDefaultValue(
            'CADDIE_BAG_DIV',
        );

        const {caddieFee, cartNo} = await this.getCaddieFee(
          defaultCaddieArgmtDiv,
          defaultCaddieBagDiv,
          caddieFeeDiv,
          holeDiv,
          this.teamInformation?.visitDate,
          caddieId
        );

        const editedRecords = gridRef.getBatchCurrentViewRecords();
        if (editedRecords.length === 0) {
          gridRef.addRecord({
            ...caddieInfo,
            caddieFee,
            _rid: 1,
            caddieArgmtDiv: defaultCaddieArgmtDiv,
            caddieBagDiv: defaultCaddieBagDiv,
            chkinId: this.teamInformation.checkInId,
            visitDate: moment(this.bsnDate).format('YYYY-MM-DD'),
            cartNo: cartNo
          });
        } else {
          // check duplicate in editedRecords
          if (
              !(
                  _.findIndex(
                      editedRecords,
                      ({caddieId: _caddieId}) => _caddieId === caddieId,
                  ) < 0
              )
          ) {
            return;
          }

          const {_rid} = _.maxBy(editedRecords, '_rid');
          gridRef.addRecord({
            ...caddieInfo,
            caddieFee,
            _rid: _rid + 1,
            caddieArgmtDiv: defaultCaddieArgmtDiv,
            caddieBagDiv: defaultCaddieBagDiv,
            chkinId: this.teamInformation.checkInId,
            visitDate: moment(this.bsnDate).format('YYYY-MM-DD'),
            cartNo: cartNo
          });
        }
      }
    },
    async putCaddieArgmtInfo() {
      try {
        const gridLength = this.$refs.caddiesArrangementGrid.getBatchCurrentViewRecords().length;
        const {addedRecords, changedRecords, deletedRecords} = this.$refs[
            'caddiesArrangementGrid'
            ].getBatchChanges();

        if (
          !(addedRecords.length > 0) &&
          !(changedRecords.length > 0) &&
          !(deletedRecords.length > 0)
        ) {
          this.errorToast("변경사항이 없습니다");
          return;
        }

        const validate = this.caddiesList.columns.find(
            (item) =>
                item.field === this.validateCaddieRecords(addedRecords) ||
                item.field === this.validateCaddieRecords(changedRecords),
        );
        if (validate) {
          return this.errorToast(
              this.$t('main.validationMessage.requiredMessage', [
                validate.headerText,
              ]),
          );
        }

        await putCaddieArgmtInfo(
            addedRecords,
            changedRecords,
            deletedRecords,
            this.teamInformation.visitDate,
        );

        this.infoToast('처리되었습니다');

        if ((addedRecords.length > 0 || (deletedRecords.length > 0 && gridLength))
            && !Object.prototype.hasOwnProperty.call(this.selectedRow, "caddieId")) {
          await this.handleArrangementChartPrint();
        }
        this.clearTeamInformation();
        await this.refreshReservationsAndCaddies();
      } catch (e) {
        console.error(e);
      }
    },
    async updateAddVisitConfirmStateOK() {
      if (!this.teamInformation || !this.teamInformation.checkInId) {
        return;
      }

      const data = {
        visitDate: this.teamInformation.visitDate,
      };

      await updateAddVisitConfirmStateOK(this.teamInformation.checkInId, data);
    },

    // About Reservations
    getCourseNameFromCommonCode(courseCode) {
      const comCode = this.courseNames.find(
          (code) => code.comCode === courseCode,
      );

      return comCode ? comCode.comName : '';
    },
    focusFoundNext() {
      if (!this.query) {
        return;
      }

      const next = this.founds.next();
      if (!next.value || next.value.query !== this.query) {
        const result = this.findInGrids(this.query);
        if (result.length === 0) {
          this.displayFounds = false;
          this.errorToast('검색결과 없음');
        } else {
          this.displayFounds = true;
          this.founds = result[Symbol.iterator]();
          const {rowIndex, gridIndex} = this.founds.next().value;

          this.courseReserveTimeActiveIndex = gridIndex;
          this.$refs['coursesGrids'][gridIndex].selectRow(rowIndex);
        }
      } else {
        const {rowIndex, gridIndex} = next.value;
        this.courseReserveTimeActiveIndex = gridIndex;
        this.$refs['coursesGrids'][gridIndex].selectRow(rowIndex);
      }
    },
    findInGrids(query) {
      const result = [];

      this.$refs['coursesGrids'].some((grid, gridIndex) => {
        grid.getDataRows().some(({cells}, rowIndex) => {
          Array.prototype.some.call(cells, (cell) => {
            const {cellIndex, innerText} = cell;
            cell.classList.remove(this.$t('className.grid.cellFound'));
            if (
                (
                    cellIndex === 3 ||
                    cellIndex === 5 ||
                    cellIndex === 7 ||
                    cellIndex === 8 ||
                    cellIndex === 9 ||
                    cellIndex === 10 ||
                    cellIndex === 11 ||
                    cellIndex === 12
                ) && innerText.includes(query)
            ) {
              cell.classList.add(this.$t('className.grid.cellFound'));
              const targetIndex = _.findIndex(
                  result,
                  ({gridIndex: _gridIndex, rowIndex: _rowIndex}) =>
                      _gridIndex === gridIndex && _rowIndex === rowIndex,
              );
              if (targetIndex < 0) {
                result.push({
                  gridIndex,
                  rowIndex,
                  query,
                  cells: [cell],
                });
              } else {
                result[targetIndex].cells.push(cell);
              }
            }
          });
        });
      });
      return result;
    },
    findAndSelectRowAsCourseCodeAndTimeId(courseCode, timeId) {
      const gridIndex = _.findIndex(
          this.reservationsAndCaddies.courses,
          ({courseCode: _courseCode}) => courseCode === _courseCode,
      );
      if (gridIndex < 0) {
        return;
      }

      let targetRowIndex = this.$refs.coursesGrids[gridIndex].getRowIndexByPrimaryKey(timeId);
      if (targetRowIndex < 0) {
        return;
      }

      this.courseReserveTimeActiveIndex = gridIndex;
      this.$refs.coursesGrids[gridIndex].selectRow(targetRowIndex);
    },

    // About Team Information
    collapseTeamInformation() {
      this.visibleTeamInformation = !this.visibleTeamInformation;
    },
    clearTeamInformation() {
      this.teamInformation = {
        checkInId: '',
        visitors: [],
        caddies: [],
        timeId: '',
        resveId: '',
        timeStatus: '',
        partDiv: '',
        startTime: '',
        startCourse: '',
        holeDiv: '',
        resveName: '',
        resveKind: '',
        resveChannel: '',
        grpNo: '',
        GrpKind: '',
        GrpName: '',
        memoFlag: '',
        visitConfirmFlag: '',
        caddieFlag: '',
        optFlag: '',
      };
      this.originalCaddies = [];
    },
    getSexLocaleStringFromCommonCode(sexCode) {
      return this.sexLocaleStrings
          .find(code => code.comCode === sexCode)?.comName;
    },
    getMemberDivLocaleStringFromCommonCode(memberDiv) {
      return this.memberDivLocaleStrings
          .find(code => code.comCode === memberDiv)?.comName;
    },
    getHoleDivLocaleStringFromCommonCode(holeDiv) {
      return this.holeDivLocaleStrings
          .find(code => code.comCode === holeDiv)?.comName;
    },

    // From Servers
    async refreshAll() {
      await this.refreshReservationsAndCaddies();
      await this.refreshTeamInformation();

      // 일마감 여부 확인.
      await GolfErpAPI.fetchCloseCheck({
        bsnDateFrom: this.bsnDateValueModel,
        bsnDateTo: this.bsnDateValueModel,
        storeCode: 'FRONT',
      });
    },
    async refreshReservationsAndCaddies() {
      this.reservationsAndCaddies = await this.fetchReservationsAndCaddies();
      //라인옵션
      if (this.isTimeListLineOption) {
        const times = (this.partDiv) ? this.reservationsAndCaddies.times.filter(time => time.partDiv === this.partDiv) : this.reservationsAndCaddies.times ;
        this.reservationsAndCaddies.courses.forEach((course, idx) => {
          //타임리스트로 기본형 모든 타임 깊은복사, 같은 타입이 있으면 attr 합치기
          let coursedata = times.map(item => {
            return { ...item , ...course.reservations.filter(revItem => revItem.startTime === item.startTime)[0]};
          });
          course.reservations = coursedata;
        });
      }
      //1,2,3부별 Botton라인 Flag 지정
      this.reservationsAndCaddies.courses.forEach(course => {
        course.reservations.forEach((item, idx) =>{
          item.partDivLineFlag = (idx != 0 && item.partDiv !== course.reservations[idx -1]?.partDiv) ? true : false;
        });
      });
      this.caddieLength = this.reservationsAndCaddies.caddies.length;

      //스크롤 이벤트
      await this.$nextTick(() =>{
        this.reservationsAndCaddies.courses.forEach((course, idx) => {
          const div = document.getElementById(`courseGrid${idx}`);
          if (div) {
            //div.removeEventListener("wheel", this.onWhl, true);
            div.removeEventListener("scroll", this.onScl, true);
            //div.addEventListener("wheel", this.onWhl, true);
            div.addEventListener("scroll", this.onScl, true);
          }
        });
      });
    },
    onScl(evt) {
      //div ID 인덱스 번호 추출, courseGrid0
      const currentIdx = (evt.currentTarget.id).slice(-1);
      const top = this.$refs.coursesGrids[currentIdx].getContent().children[0].scrollTop;

      this.$refs.coursesGrids.forEach((gird, idx) => {
        if (idx !== Number.parseInt(currentIdx)) {
          gird.getContent().children[0].scrollTop = top;
        }
      });
    },

    clearMemoBySelectedRowIndex() {
      for (let i = 0; i < this.reservationsAndCaddies.courses.length; i++) {
        const selecteRowIndexes = this.$refs.coursesGrids[i].getSelectedRowIndexes();
        if (selecteRowIndexes.length < 1) {
          this.clearMemo();
        }
      }
    },
    async refreshTeamInformation() {
      if (!this.isTeamSelected) {
        return;
      }

      const checkInId = this.teamInformation.checkInId;
      const currentViewRecords = this.$refs.coursesGrids[
          this.courseReserveTimeActiveIndex
          ]?.getBatchCurrentViewRecords();
      const data = currentViewRecords.find(
          (item) => item.chkinId === checkInId,
      );
      const {
        timeId,
        resveId,
        timeStatus,
        partDiv,
        startTime,
        startCourse,
        holeDiv,
        resveName,
        resveKind,
        resveChannel,
        grpNo,
        GrpKind,
        GrpName,
        memoFlag,
        visitConfirmFlag,
        caddieFlag,
        optFlag,
        visitDate,
      } = data;

      const courseCode = this.reservationsAndCaddies.courses[
          this.courseReserveTimeActiveIndex
          ].courseCode;

      const {visitors, caddies} = await this.fetchTeamInformation(checkInId);

      this.teamInformation = {
        visitDate,
        courseCode,
        checkInId,
        visitors,
        caddies,
        timeId,
        resveId,
        timeStatus,
        partDiv,
        startTime,
        startCourse,
        holeDiv,
        resveName,
        resveKind,
        resveChannel,
        grpNo,
        GrpKind,
        GrpName,
        memoFlag,
        visitConfirmFlag,
        caddieFlag,
        optFlag,
      };
      this.originalCaddies = this.teamInformation.caddies;
    },
    async fetchReservationsAndCaddies() {
      let data;
      data = await this.getCaddieArgmtInfoList(
          moment(this.bsnDate).format('YYYY-MM-DD'),
          this.partDiv || undefined,
          this.notArrangementTimeFlag,
          this.rainCancelTimeFlag,
      );
      if(!data.caddieAttendList.length) {
        await createAttendInfo(this.bsnDateValueModel);
        data = await this.getCaddieArgmtInfoList(
            moment(this.bsnDate).format('YYYY-MM-DD'),
            this.partDiv || undefined,
            this.notArrangementTimeFlag,
            this.rainCancelTimeFlag,
        );
      }
      return {
        courses: data.courseReserveTimeList
            ? data.courseReserveTimeList.map(({courseCode, reserveTimeList}) => ({
              courseCode,
              reservations: reserveTimeList.map((reserveTime, index) => ({
                ...reserveTime,
                _rid: index + 1,
              })),
            }))
            : [],
        caddies: data.caddieAttendList
            ? data.caddieAttendList.map((record, index) => ({
              ...record,
              _rid: index + 1,
              snoMoveFlag: !!record.snoMoveFlag,
            }))
            : [],
        times: data.allTimeList ? data.allTimeList : [] ,
      };
    },
    async fetchTeamInformation(checkInId) {
      const {
        teamVisitInfoList,
        caddieArgmtList,
      } = await this.getCaddieArgmtInfoDetaill(checkInId);
      return {
        checkInId,
        visitors: teamVisitInfoList.map(data=>{
          return {
            ...data,
            lockerPrtNo: data.lockerNo === '000' || data.lockerNo === '999'
            ? "미배정"
            : data.lockerPrtNo,
          };
        }) || [],
        caddies: caddieArgmtList
            ? caddieArgmtList.map((record, index) => ({
              ...record,
              _rid: index + 1,
            }))
            : [],
      };
    },

    // API
    async getCaddieArgmtInfoList(
        bsnDate,
        partDiv,
        notArrangementTimeFlag,
        rainCancelTimeFlag,
    ) {
      try {
        const {
          value: {courseReserveTimeList, caddieAttendList, allTimeList},
        } = await getCaddieArgmtInfoList(
            bsnDate,
            partDiv,
            notArrangementTimeFlag,
            rainCancelTimeFlag,
        );
        return {courseReserveTimeList, caddieAttendList, allTimeList};
      } catch (e) {
        console.error(e);
      }
    },
    async getCaddieArgmtInfoDetaill(chkinId) {
      try {
        const {
          value: {caddieArgmtList, teamVisitInfoList},
        } = await getCaddieArgmtInfoDetaill(chkinId);
        return {
          caddieArgmtList,
          teamVisitInfoList,
        };
      } catch (e) {
        console.error(e);
      }
    },
    async getCaddieFee(caddieArgmtDiv, caddieBagDiv, caddieFeeDiv, holeDiv, visitDate, caddieId) {
      try {
        const {
          value: {
            caddieFee,
            cartNo
          },
        } = await getCaddieFee(
          caddieArgmtDiv,
          caddieBagDiv,
          caddieFeeDiv,
          holeDiv,
          visitDate,
          caddieId
        );
        return {
          caddieFee,
          cartNo
        };
      } catch (e) {
        console.error(e);
      }
    },

    // validation
    validateCaddieRecords(records) {
      const validateList = [
        'chkinId',
        'caddieId',
        'visitDate',
        'caddieArgmtDiv',
        'caddieBagDiv',
        'caddieFeeDiv',
        'cartNo',
      ];
      let result = null;
      records.some((item) => {
        validateList.some((v) => {
          if (!item[v]) {
            result = v;
          }
          return !item[v];
        });
      });
      return result;
    },
    courseReserveTimeGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if (field === 'memo') {
        node.classList.add(this.$t("className.grid.memoArea"));
      } else if (field === 'holeDiv') {
        node.classList.add(this.$t("className.grid.nonePadding"));
      } else if (field === 'resveName') {
        node.classList.add(this.$t("className.grid.clickArea"));
        node.classList.add(this.$t("className.grid.nonePadding"));
        node.classList.add(this.$t("className.grid.paddingLeft10"));
      } else if (field === 'grpName') {
        node.classList.add(this.$t("className.grid.nonePadding"));
      } else if (field === 'caddieName') {
        node.classList.add(this.$t("className.grid.caddieArea"));
      } else if (field === 'visitConfirmFlag') {
        node.classList.add(this.$t("className.grid.nonePadding"));
      }
    },
    courseReserveTimeGridQueryCellInfo(args) {
      if (!(args.column.edit.parent.sortedColumns.length > 0)) {
        if (args.data.partDivLineFlag) {
          args.cell.classList.add(
            this.$t("className.grid.devReservationPartDivLine")
          );
        }
      }

      const {
        column: {field},
        data,
        cell,
      } = args;
      if (field === 'startTime') {
        const colorValue = commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            data.timeStatus,
        );
        if (colorValue) {
          cell.style.backgroundColor = colorValue;
        }
        if (data.timeStatus === "RAIN_TEAM") {
          cell.style.color = "#FFF";
        }

        if (!data.resveName) {
          cell.style.backgroundColor = "#cccccc";
        }
      } else if (field === 'holeDiv') {
        const colorValue = commonCodesGetColorValue(
            'HOLE_DIV',
            data.holeDiv,
        );
        if (colorValue) {
          cell.style.backgroundColor = colorValue;
        }
      } else if (field === 'resveName') {
        cell.style.backgroundColor = commonCodesGetColorValue(
            'MEMBER_DIV',
            data.memberDiv,
        );
        if (data.resveName) {
          cell.classList.add(this.$t("className.grid.clickArea"));
        }
        if(data.resveKind === "TRANS") {
          cell.style = "position : relative";
          let divTag = document.createElement('div');
          divTag.classList.add('mandate');
          //cell.innerHTML = `<span style='font-weight: bold; background: yellow'>${data[field]}</span>`;
          cell.innerHTML = `<span style='font-weight: bold; background: yellow'>${data.transName}</span>`;
          cell.appendChild(divTag);
        }
        if (data.cartKind === 'LIMOUSINE') {
          const cartImage = document.createElement('img');
          cartImage.src = require('../../../assets/images/common/cart.png');
          cartImage.style.position = 'relative';
          cartImage.className = `cart-image`;

          const space = document.createTextNode('\u00A0');
          cell.prepend(space);
          cell.prepend(cartImage);
        }
      } else if (field === 'memo') {
        if (data.memoFlag) {
          if (data.newMemoFlag && commonCodesGetStandardInfo("memoConfirmFlag")) {
            cell.classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            cell.classList.add(this.$t("className.grid.memoArea"));
          }
        }
      } else if (field.startsWith('visitName')) {
        for (let i = 1; i <= 5; i++) {
          if (field === `visitName${i}`) {
            const visitAditFlag = data[`visitAditFlag${i}`];
            const sexCode = data[`visitSexCode${i}`];
            const colorValue = commonCodesGetColorValue('SEX_CODE', sexCode);
            if (colorValue) {
              cell.style.color = colorValue;
            }
            if (data[`visitName${i}`] && !data[`visitConfirmFlag${i}`]) {
              cell.style.backgroundColor = "#dedede";
            }
            if (visitAditFlag === '1') {
              cell.style.backgroundColor = commonCodesGetColorValue('COLOR_TIME_STATUS', 'ADD_VISIT_TEAM');
            }
            break;
          }
        }
      } else if (field === "caddieName") {
        if (data.selfOpenFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue("COLOR_TIME_STATUS", "SELF_OPEN_FLAG");
        }
      }
    },
    caddiesGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if (field === 'caddieName') {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    caddiesGridQueryCellInfo({cell, column, data}) {
      // 남자 캐디 글자색
      if (column.field === 'caddieName') {
        if (data.sexCode && data.sexCode.toUpperCase() === 'M') {
          cell.style.color = 'blue';
        }
        if (data.caddieName) {
          cell.classList.add(this.$t("className.grid.clickArea"));
        }
      } else if (column.field === 'caddieAttendDiv') {
        cell.style.backgroundColor = commonCodesGetColorValue(
            'CADDIE_ATTEND_DIV',
            data.caddieAttendDiv,
        );
      }
    },
    caddiesArrangementGridLoad() {
      this.$refs.caddiesArrangementGrid.$el.addEventListener('keydown', (e) => {
        setGridUneditKeydownProcess(
            this.$refs.caddiesArrangementGrid,
            e,
            this.currentEditingCellIndex,
        );
      });
    },
    caddiesGridActionComplete(args) {
      if (args.requestType === 'filtering' && args.action === 'clearFilter') {
        this.caddieLength = this.reservationsAndCaddies.caddies.length;
      } else if (args.requestType === 'filtering') {
        this.caddieLength = this.$refs.caddiesGrid.getFilteredRecords().length;
      } else if (args.requestType === 'refresh') {
        this.caddieLength = this.reservationsAndCaddies.caddies.length;
        if (this.caddieAttendDiv === 'ATT') {
          this.$refs.caddiesGrid.filterByColumn(
              'caddieAttendDiv',
              'endswith',
              '출근',
          );
        } else {
          this.$refs.caddiesGrid.clearFiltering();
        }
      }
      if (args.requestType === 'sorting') {
        this.$refs.caddiesGrid.selectRow(0);
      }
    },
    async findCaddie() {
      const searchValue = this.searchValue.trim();
      if (!searchValue) {
        this.infoToast(this.$t('main.popupMessage.noSearchValue'));
        return;
      }
      const gridInstance = this.$refs.caddiesGrid;
      const rowIndex = gridInstance.getBatchCurrentViewRecords()
                        .findIndex(item => item.caddieName.includes(searchValue) || item.caddieNo === Number(searchValue));
      if (rowIndex < 0) {
        this.infoToast(this.$t('main.popupMessage.noSearchResult'));
        return;
      }
      await this.$nextTick();
      gridInstance.selectRow(rowIndex);
    },
    onBsnDateChange(args) {
      this.bsnDateInfo = args;

      this.handleFetchClick();
    },
    onCourseDivChanged() {
      this.selectedCourseDiv = event.target.value;
      this.handleFetchClick();
    },
    onCaddieAttendDivChanged() {
      if (this.caddieAttendDiv === 'ATT') {
        this.$refs.caddiesGrid.filterByColumn(
            'caddieAttendDiv',
            'equal',
            '출근',
        );
      } else {
        this.$refs.caddiesGrid.clearFiltering();
      }
    },
    async onETCShortcutMenuSelected(args) {
      switch (args.item.id) {
        case 1:
          this.handleArrangementNoticeClick();
          break;
        case 2:
          this.smsSend();
          break;
        case 3:
          this.builtInGuestNotificationTalkSendPopupOpen();
          break;
        case 4:
          this.openCaddieFee();
          break;
      }
    },
    /**
     * 캐디피 영수증 발행
     * @param caddieInfo
     */
    async openCaddieFee() {
      if (!(this.teamInformation.caddies.length > 0)) {
        this.errorToast("배정된 캐디가 존재하지 않습니다.");
        return;
      }

      if (await this.confirm("캐디피 영수증을 출력하시겠습니까?")) {
        this.isCaddieFeePopupOpen = true;
        this.$nextTick(() => {
          this.$refs.caddieFeePopup.showCaddieFeePopup(
              "",
              this.teamInformation.caddies[0],
              this.teamInformation.caddies[0].visitDate
          );
        });
      }
    },
    caddieFeePopupClosed: function () {
      this.isCaddieFeePopupOpen = false;
    },
    handleCaddieBatchInfoHeaderClick() {
      this.isCaddieBatchInfoVisible = !this.isCaddieBatchInfoVisible;
    },
    onCaddiesArrangementGridClick(e) {
      if (e.srcElement) {
        const columnName = e.srcElement.getAttribute('column-name');
        if (columnName) {
          switch (columnName) {
            case 'caddieBagDiv':
            case 'caddieArgmtDiv':
              this.gridEjsDropdownlistEditTemplateEventBus.$emit(
                  'click-open',
                  columnName,
              );
              break;
          }
        }
      }
    },
    caddiesArrangementGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if (field === 'caddieName') {
        node.classList.add(this.$t("className.grid.caddieArea"));
      }
    },
    caddiesArrangementGridQueryCellInfo(args) {
      // 컬럼 속성 추가 - 클릭 시 클릭한 컬럼 여부 파악을 위함
      args.cell.setAttribute('column-name', args.column.field);
    },
    isTeamInfoAndCaddiesInfoListIsChanged() {
      let isModified =
          JSON.stringify(this.teamInformation.caddies) !==
          JSON.stringify(this.originalCaddies);
      if (isModified === false) {
        const gridInstance = this.$refs.caddiesArrangementGrid;
        if (gridInstance) {
          const batchChangesData = gridInstance.getBatchChanges();
          if (
              batchChangesData.addedRecords.length > 0 ||
              batchChangesData.changedRecords.length > 0 ||
              batchChangesData.deletedRecords.length > 0
          ) {
            isModified = true;
          }
        } else {
          return false;
        }
      }
      return isModified;
    },
    cellphoneNumberFormatter(data) {
      if (data === undefined || data === null) {
        return '';
      }

      if (
          /^([\d]){3}-([\w*]){3}-([\d]){4}$/.test(data) ||
          /^([\d]){3}-([\w*]){4}-([\d]){4}$/.test(data)
      ) {
        return data;
      }

      if (/^([\d]){3}([\w*]){3}([\d]){4}$/.test(data)) {
        return data.replace(/^(\d{3})([\w*]{3})(\d{4})$/, '$1-$2-$3');
      }

      if (/^([\d]){3}([\w*]){4}([\d]){4}$/.test(data)) {
        return data.replace(/^(\d{3})([\w*]{4})(\d{4})$/, '$1-$2-$3');
      }

      return data;
    },
    async smsSend() {
      if (!this.teamInformation.checkInId) {
        this.errorToast('선택된 팀정보가 존재하지 않습니다.');
        return;
      }

      if (!(this.teamInformation.caddies.length > 0)) {
        this.errorToast('캐디가 배치되지 않았습니다.');
        return;
      }

      const visits = this.teamInformation.visitors;
      const caddieName = this.teamInformation.caddies
          .map((caddie) => caddie.caddieName)
          .join(',');
      const cartNo = this.teamInformation.caddies
          .map((caddie) => caddie.cartNo + "번")
          .join(',');
      const courseTime =
          this.teamInformation.startTime.substring(0, 2) +
          '시' +
          this.teamInformation.startTime.substring(3, 5) +
          '분' +
          '(' +
          commonCodesGetComName('COURSE_CODE', this.teamInformation.startCourse) +
          ')';

      const smsSendInfo = {
        type: 'CADDIE_ASSIGN',
        bizName: commonCodesGetStandardInfo('bizName'),
        bizNameLocation: commonCodesGetStandardInfo('bizNameLocation'),
        kakaoSendFlag: false,
        kakaoKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo('kakaoDsptchKey'),
        kakaoTemplateCode: null,
        subject: null,
      };
      const smsWords = await GolfErpAPI.fetchAllSmsWords();
      const smsSendType = smsWords
          .find(data => data.smsSendType === smsSendInfo.type);

      let message =
          (smsSendType.smsWords != null ? smsSendType.smsWords : '') +
          (smsSendType.aditWords != null ? '\n' + smsSendType.aditWords : '');

      message = message + (smsSendInfo.bizNameLocation === "DOWN" ? (smsSendInfo.bizName != null ? '\n- ' + smsSendInfo.bizName : '') : "");

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.kakaoKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];

      visits.forEach((visit) => {
        if (!visit.contactTel) {
          return;
        }
        let smsInfo = {
          type: smsSendInfo.type,
          kakaoFlag: smsSendInfo.kakaoSendFlag,
          smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.kakaoKind : null,
          kakaoDispatchKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoDispatchKey
              : null,
          kakaoTemplateKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoTemplateCode
              : null,
          key: 0,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: visit.visitName,
          receiverNumber: visit.contactTel,
          subject: smsSendInfo.subject,
          message: message,
          replaceDataMap: {
            headerMemo: smsSendInfo.bizName,
            visitName: visit.visitName + " " + commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", visit.memberDiv, 1),
            resveDate: getFormattedDate(
                this.teamInformation.visitDate,
                'MM월DD일',
            ),
            dwName: commonCodesGetComName(
                'DW_CODE',
                this.teamInformation.dwCode,
            ),
            resveCourseTime: courseTime,
            resveTime: this.teamInformation.startTime,
            resveCourse: commonCodesGetComName("COURSE_CODE", this.teamInformation.startCourse),
            cartNo: cartNo,
            caddieName: caddieName,
          },
          dispatchNumber: smsSendType.dsptchNo,
          sender: 'ERP',
        };

        smsRequestList.push(smsInfo);
      });

      if (!(smsRequestList.length > 0)) {
        this.infoToast("배치알림 SMS 전송할 내장객 연락처 정보가 없습니다");
        return;
      }

      await GolfErpAPI.sendSMS(smsRequestList);
      this.infoToast(smsRequestList.length + "명의 내장객에게 배치알림 SMS 전송이 완료되었습니다");
    },
    async builtInGuestNotificationTalkSendPopupOpen(){
      if (!this.teamInformation.checkInId) {
        this.infoToast('내장팀을 선택해 주시기 바랍니다');
        return;
      }
      this.isBuiltInGuestNotificationTalkSendPopupOpened = true;
    },
    builtInGuestNotificationTalkSendPopupClosed() {
      this.isBuiltInGuestNotificationTalkSendPopupOpened = false;
    },
    async caddiesArrangementGridKeyPressed(args) {
      if (args.keyCode === 13 && args.target.name === "cartNo") {
        try {
          this.$refs.caddiesArrangementGrid.saveCell();

          const {addedRecords, changedRecords, deletedRecords} = this.$refs[
            'caddiesArrangementGrid'
            ].getBatchChanges();

          if (addedRecords.length === 0 && changedRecords.length === 0 && deletedRecords.length === 0) {
            return;
          }

          const validate = this.caddiesList.columns.find(
            (item) =>
              item.field === this.validateCaddieRecords(addedRecords) ||
              item.field === this.validateCaddieRecords(changedRecords),
          );
          if (validate) {
            return this.errorToast(
              this.$t('main.validationMessage.requiredMessage', [
                validate.headerText,
              ]),
            );
          }

          await putCaddieArgmtInfo(
            addedRecords,
            changedRecords,
            deletedRecords,
            this.teamInformation.visitDate,
          );

          this.infoToast('처리되었습니다');
          if(!Object.prototype.hasOwnProperty.call(this.selectedRow, "caddieId")){
            await this.handleArrangementChartPrint();
          }
          this.clearTeamInformation();
          await this.refreshReservationsAndCaddies();
        } catch (e) {
          console.error(e);
        }
      }
    },
    async handleArrangementChartPrint() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.


      let {
        value: { tcketNtcnList },
      } = await getCaddieArrangementNotice();

      tcketNtcnList = tcketNtcnList.filter(data => data.useFlag).map(data => {
        return data.ntcnMessage;
      });

      const tcketNtcn = tcketNtcnList.join("\n");

      const {
        value: { ccName, timeData, teamVisitInfoList, caddieArgmtInfoList },
      } = await getCaddieArrangementPrint(this.teamInformation.checkInId);

      this.caddieArgmtPrintData = {
        ccName,
        timeData,
        teamVisitInfoList: teamVisitInfoList.map(visitor => ({
          ...visitor,
          memberDivName: commonCodesGetComName("MEMBER_DIV", visitor.memberDiv),
          sexCodeName: commonCodesGetComName("SEX_CODE", visitor.sexCode),
          visitorInfo: commonCodesGetComName("SEX_CODE", visitor.sexCode) + "/" + visitor.visitName + "(" + commonCodesGetComName("MEMBER_DIV", visitor.memberDiv) + ")",
          lockerPrtNo :visitor.lockerNo === '000' || visitor.lockerNo === '999'
              ? "미배정"
              : visitor.lockerPrtNo
        })),
        caddieArgmtInfoList: caddieArgmtInfoList.map(caddie => ({
          ...caddie,
          caddieNameAndNo: caddie.caddieName + "(" + caddie.caddieNo + ")",
        })),
      };

      const printSetData = {
        startTime: this.caddieArgmtPrintData.timeData.startTime,
        startCourse: commonCodesGetComName(
          "COURSE_CODE",
          this.caddieArgmtPrintData.timeData.startCourse),
        visitInfoList: this.caddieArgmtPrintData.teamVisitInfoList.map((teamVisitInfo, index) => {
          const caddieNameAndNo = this.caddieArgmtPrintData.caddieArgmtInfoList.map(caddie => caddie.caddieNameAndNo);
          const cartNo = this.caddieArgmtPrintData.caddieArgmtInfoList.map(caddie => caddie.cartNo);

          return {
            ...teamVisitInfo,
            caddieNameAndNo: caddieNameAndNo[index],
            cartNo: cartNo[index],
          };
        }),
        caddieInfoList: this.caddieArgmtPrintData?.caddieArgmtInfoList?.length > 0 ?
            this.caddieArgmtPrintData.caddieArgmtInfoList : this.createDummyCaddieList,
        ...(this.caddieArgmtPrintData.grpName && {grpName: this.caddieArgmtPrintData.grpName}),
        ...(this.caddieArgmtPrintData.grpName && {grpNameTitle: "단 체 명 : "}),
        ccName: this.caddieArgmtPrintData.ccName,
        visitDate: getTodayDateTime(),
        ntcnMessage: tcketNtcn
      };
      const {
        isBillPrinterUse,
        isKitchenBillPrinterUse,
      } = getDeviceInfo();

      const config = {
        "useBillPrinter": isBillPrinterUse,
        "useKitchenPrinter": isKitchenBillPrinterUse,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "CADDIE_ARGMT", true, printSetData, config);

    },
    memoConfirm(args) {
      if (this.reservationsAndCaddies.courses.length > 0) {
        let isBreak = false;
        for (let i = 0, j = this.reservationsAndCaddies.courses.length; i<j; i++) {
          if (isBreak) {
            break;
          }

          for (let ii = 0, jj = this.reservationsAndCaddies.courses[i].reservations.length; ii<jj; ii++) {
            if (this.reservationsAndCaddies.courses[i].reservations[ii].resveId === args.resveId) {
              this.reservationsAndCaddies.courses[i].reservations[ii].newMemoFlag = args.newMemoFlag;
              isBreak = true;
              break;
            }
          }
        }
      }
    },
    handleCaddiesGridKeyPressed(e) {
      if(e.key.toLowerCase() === 'enter') {
        this.notSelectTeamWarnToast();
        const row = this.$refs.caddiesGrid.getSelectedRecords()[0];
        row.snoMoveFlag = true;
        this.addCaddieArrangement(row);

        setTimeout(() => {
          this.$refs.caddiesGrid.selectRow(
              this.$refs.caddiesGrid.getBatchCurrentViewRecords().findIndex(item => item.caddieId === this.selectedCaddie.caddieId)
          );
        }, 300);
        console.log(this.selectedCaddie);
        //   this.$refs.searchValue.focus();
      }
    },
    notSelectTeamWarnToast() {
      if (!this.isTeamSelected) {
        this.errorToast('팀을 선택해주세요');
        return;
      }
    },
  },
  computed: {
    isPopupOpened() {
      return (
          this.iscaddieArrangementSearchNamePopupOpened ||
          this.isCaddieArrangementHoleDivPopupOpened ||
          this.isCaddieArrangementNoticePopupOpened ||
          this.isCaddieArrangementPrintPopupOpened ||
          this.isCaddieFeePopupOpen ||
          this.isReservationTimeDetailPopupOpen ||
          this.isAddVocPopupOpen ||
          this.isBuiltInGuestNotificationTalkSendPopupOpened
      );
    },
    widthBox(){
      return `width:calc((100%/${this.courseCodeFilter.length}) + 2px) !important`;
    },
    courseCodeFilter() {
      let courseCodeIdx2 = [];
      commonCodesGetCommonCodeByIdx("COURSE_CODE", 2).map(item => {
        item.comCodeAttrbList.map(attrb => {
          courseCodeIdx2.push(attrb);
        });
      });
      return this.courseDiv.includes("%") && this.courseDiv.length === 1 ?
          courseCodeIdx2.map(item => item.comCode) :
          courseCodeIdx2.filter(item => this.courseDiv.includes(item.comCode)).map(item => item.comCode);
    },
    caddiesArrangementGridQAggregates() {
      return [
        {
          columns: [
            {
              field: 'caddieName',
              aggregationType: 'TotalCaption',
              customAggregate: '합계',
            },
            {
              field: 'caddieFee',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ];
    },
    shortcutMenuItems() {
      return [
        {
          id: 1,
          text: '캐디근태 생성',
        },
        {
          id: 2,
          text: "VOC",
        }
      ];
    },
    etcMenuItems() {
      return [
        {
          id: 1,
          text: '알림문구',
        },
        {
          id: 2,
          text: '배치알림(SMS)',
        },
        {
          id: 3,
          text: '내장객 알림톡',
        },
        {
          id: 4,
          text: '캐디피 영수증',
        },
      ];
    },
    memberLinkCardWidth() {
      if (!this.teamInformation || !this.teamInformation.visitors) {
        return 252;
      }
      return this.teamInformation.visitors.length * 252;
    },
    // About Commons
    dayOfWeekCaptionStyle() {
      return {
        color: getDayOfWeekCaptionColor(this.bsnDate),
      };
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.bsnDate);
    },
    weekdayWeekendCaptionStyle() {
      return {
        color: getWeekdayWeekendCaptionColor(this.bsnDate),
      };
    },
    weekdayWeekendCaption() {
      return getWeekdayWeekendCaption(this.bsnDate);
    },
    partDivDropdownListProps() {
      const partDiv = commonCodesGetCommonCode('PART_DIV', true);
      if (partDiv.findIndex((obj) => obj.comCode === '') < 0) {
        partDiv.unshift({
          comCode: '',
          comName: '전체',
          codeAbrv: '',
          defaultFlag: false,
        });
      }
      return {
        dataSource: partDiv,
        fields: {text: 'comName', value: 'comCode'},
      };
    },
    selfOpenFlagItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
          'COLOR_TIME_STATUS',
          'SELF_OPEN_FLAG',
        ),
      };
    },
    resveTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'RESVE_TEAM',
        ),
      };
    },
    checkinTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'CHKIN_TEAM',
        ),
      };
    },
    argmtTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'ARGMT_TEAM',
        ),
      };
    },
    unargmtTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'UNARGMT_TEAM',
        ),
      };
    },
    rainTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'RAIN_TEAM',
        ),
      };
    },
    addVisitTeamItalicStyle() {
      return {
        'background-color': commonCodesGetColorValue(
            'COLOR_TIME_STATUS',
            'ADD_VISIT_TEAM',
        ),
      };
    },
    courseNames() {
      return commonCodesGetCommonCode('COURSE_CODE');
    },
    sexLocaleStrings() {
      return commonCodesGetCommonCode('SEX_CODE');
    },
    memberDivLocaleStrings() {
      return commonCodesGetCommonCode('MEMBER_DIV');
    },
    holeDivLocaleStrings() {
      return commonCodesGetCommonCode('HOLE_DIV');
    },

    // About Reservations
    reservationsCountsEachStatus() {
      const counts = {
        ALL_TEAM: 0,
        RESVE_TEAM: 0,
        CHKIN_TEAM: 0,
        ARGMT_TEAM: 0,
        UNARGMT_TEAM: 0,
        RAIN_TEAM: 0,
        ADD_VISIT_TEAM: 0,
      };
      if (this.reservationsAndCaddies && this.reservationsAndCaddies.courses) {
        this.reservationsAndCaddies.courses.forEach(({reservations}) => {
          counts['ALL_TEAM'] += reservations.length;
          reservations.forEach(({timeStatus}) => {
            counts[timeStatus]++;
          });
        });
      }
      return counts;
    },
    courseReserveTimeGridProps() {
      return {
        allowFiltering: false,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: 'startTime',
            headerText: '시간',
            minWidth: 16,
            textAlign: 'center',
            width: 44,
            type: 'string',
          },
          {
            field: 'timeId',
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: 'memoFlag',
            visible: false,
          },
          {
            allowEditing: false,
            field: 'resveName',
            headerText: '예약자',
            minWidth: 16,
            textAlign: 'Left',
            width: 65,
            type: 'string',
          },
          {
            allowEditing: false,
            field: 'grpName',
            headerText: '단체명',
            width: 65,
            textAlign: 'Left',
            type: 'string',
          },
          {
            allowEditing: false,
            field: 'caddieName',
            headerText: '캐디명',
            minWidth: 16,
            textAlign: 'Left',
            width: 75,
            type: 'string',
          },
          {
            field: 'memo',
            headerText: '',
            allowEditing: false,
            allowFiltering: false,
            minWidth: 16,
            maxWidth: 20,
            width: 20,
            type: 'string',
          },
          {
            allowEditing: false,
            displayAsCheckBox: true,
            editType: 'booleanedit',
            field: 'visitConfirmFlag',
            headerText: '확정',
            minWidth: 16,
            textAlign: 'Center',
            type: 'boolean',
            width: 30,
          },
          {
            headerText: '내장객',
            columns: [
              {
                field: 'visitName1',
                headerText: '1',
                allowEditing: false,
                width: 58,
                textAlign: 'Left',
                type: 'string',
              },
              {
                field: 'visitName2',
                headerText: '2',
                allowEditing: false,
                width: 58,
                textAlign: 'Left',
                type: 'string',
              },
              {
                field: 'visitName3',
                headerText: '3',
                allowEditing: false,
                width: 58,
                textAlign: 'Left',
                type: 'string',
              },
              {
                field: 'visitName4',
                headerText: '4',
                allowEditing: false,
                width: 58,
                textAlign: 'Left',
                type: 'string',
              },
              {
                field: 'visitName5',
                headerText: '5',
                allowEditing: false,
                width: 60,
                textAlign: 'Left',
                type: 'string',
              },
              {
                allowEditing: false,
                groupCode: 'HOLE_DIV',
                field: 'holeDiv',
                isCommonCodeField: true,
                headerText: '홀',
                minWidth: 16,
                textAlign: 'center',
                width: 46,
                type: 'string',
              },
            ],
          }
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: 'Batch',
          showConfirmDialog: false,
        },
        isNOColumnDisplay: false,
        provides: [Edit, Resize, ForeignKey],
        selectionSettings: {
          type: 'Single',
          enableToggle: false,
          mode: 'Both',
        },
      };
    },
    caddiesGridProps() {
      return {
        allowFiltering: true,
        allowResizing: true,
        columns: [
          {
            allowEditing: false,
            field: 'caddieName',
            headerText: '캐디명',
            minWidth: 16,
            textAlign: 'Left',
            type: 'string',
            width: 75,
          },
          {
            field: '_rid',
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: 'snoMoveFlag',
            visible: false,
          },
          {
            allowEditing: false,
            field: 'caddieNo',
            headerText: '번호',
            minWidth: 16,
            textAlign: 'Center',
            type: 'string',
            width: 60,
          },
          {
            allowEditing: false,
            field: 'caddieGroupDiv',
            headerText: '조',
            minWidth: 16,
            textAlign: 'Center',
            type: 'string',
            width: 40,
            visible: false,
          },
          {
            allowEditing: false,
            groupCode: 'CADDIE_ATTEND_DIV',
            field: 'caddieAttendDiv',
            isCommonCodeField: true,
            headerText: '근무구분',
            minWidth: 16,
            textAlign: 'Left',
            type: 'string',
            width: 88,
            visible: false,
          },
          {
            allowEditing: false,
            field: 'roundHole',
            headerText: '라운드홀',
            minWidth: 16,
            textAlign: 'Center',
            type: 'string',
            width: 88,
            visible: false,
          },
        ],
        dataSource: this.reservationsAndCaddies.caddies,
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: 'Batch',
          showConfirmDialog: false,
        },
        filterSettings: {type: 'Menu'},
        noColumnWidth: 30,
        provides: [Edit, Resize, ForeignKey],
        selectionSettings: {type: 'Single', enableToggle: false},
      };
    },

    // About Team Information
    isTeamSelected() {
      return !!this.teamInformation && !!this.teamInformation.checkInId;
    },
    isTeamInformationVisible() {
      return this.visibleTeamInformation && this.isTeamSelected;
    },
    bsnDateValueModel: {
      get() {
        return getFormattedDate(this.bsnDate);
      },
      set(bsnDateValueModel) {
        const bsnDate = moment(bsnDateValueModel, DATE_FORMAT_YYYY_MM_DD);
        if (bsnDate.isValid()) {
          this.bsnDate = bsnDate.toDate();
        }
      },
    },
    createDummyCaddieList() {
      return ([{
        caddieName: "   ",
        caddieNo: "   ",
        cartNo: "   ",
      }]);
    },
  },
};
</script>
